<div id="listen" class="content">

  <div style="background: #C85127;">
    <div class="container">

      <div class="col-sm-12 col-md-4 col-lg-4">

        <img src="https://loveandfurymusic.com/uploads/images/berehynia-saves-cover-1400.jpg"
             style="display: none;"
             alt="Love and Fury - Purchase Berehynia Saves"/>

        <iframe style="border: 0; width: 100%; height: 450px; margin: 0 auto; text-align: center;"  src="https://bandcamp.com/EmbeddedPlayer/track=3444582319/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
          <a href="https://loveandfury.bandcamp.com/track/berehynia-saves">Berehynia Saves by Love and Fury</a>
        </iframe>
      </div>

      <div class="col-sm-12 col-md-8 col-lg-8">

        <h3>STUDIO</h3>
        <ul class="list-unstyled">
          <li>Release: 09/15/2023</li>
          <li>Lyrics by James Stillion</li>
          <li>Composition and instruments: Gary King &copy;2023 Gary King Audio, ASCAP</li>
          <li>Vocals and vocal melodies: Gretta Harley @2023 Pas Palapatas</li>
          <li>Produced, Recorded and mixed: Gary King</li>
          <li>Recorded at Gary King's Studio, Seattle WA
            (Formerly: House of Leisure)
          </li>
          <li>Mastering: Ed Brooks - Resonant Mastering, Seattle WA</li>
          <li>Additional background vocals: Gretta Harley, Angela Castañeda and Gary King</li>
          <li>Drums: Kai Strandskov</li>
          <li>Drums recorded at Earwig Studio: Don Farwell</li>
          <li>Sunflower photograph: Jackie Kramer</li>
          <li>All Rights Reserved</li>
          <li>&#8471;Gary King Audio All rights reserved</li>

        </ul>
        <h3>BIG THANKS</h3>
        <ul class="list-unstyled">
          <li>Bob Watanabe</li>
          <li><br/><br/></li>
        </ul>

        <div>
          <h6>Bereyhnia Saves (from the poem by James Stillion)</h6>

          <ul class="list-unstyled">
            <li>Amber waves under golden rays</li>
            <li>Five grains our trident standing brave</li>
            <li>Berehynia Saves</li>
            <li><br></li>
            <li>What flag do you fly?</li>
            <li>Under a suicide gray sky</li>
            <li>From Wolfsangel to Zed</li>
            <li>Comes the black of death</li>
            <li>The white stained carmine</li>
            <li>Shroud enshrined in peacetime</li>
            <li><br>
            <li>Amber waves under golden rays</li>
            <li>Third angel’s haze</li>
            <li>Five grains our trident standing brave</li>
            <li>Our motherland, our kin assails</li>
            <li><br></li>
            <li>Mother, why?</li>
            <li><br></li>
            <li>Mother</li>
            <li><br></li>
            <li>Dancin' on the graves</li>
            <li>Under the suicide gray sky</li>
            <li>Why do we do this to ourselves?</li>
            <li>The genocide, the thievery</li>
            <li>Berehynia Saves</li>
          </ul>
        </div>

      </div>
    </div><!-- end container -->

    <hr>

    <div class="container">
      <div class="col-sm-12 col-md-4 col-lg-4">

        <img src="https://loveandfurymusic.com/uploads/images/laf-front-cover.jpg"
             style="display: none;"
             alt="Love and Fury - Purchase Album"/>
        <iframe style="border: 0; width: 100%; height: 790px; margin: 0 auto; text-align: center"
                src="https://bandcamp.com/EmbeddedPlayer/album=1766104935/size=large/bgcol=333333/linkcol=0f91ff/transparent=true/"
                seamless>
          <!--            <a href="https://loveandfury.bandcamp.com/album/planet">Planet by Love and Fury</a>-->
        </iframe>


      </div>
      <div class="col-sm-12 col-md-8 col-lg-8">

        <h3>STUDIO</h3>

        <ul class="list-unstyled">
          <li>Release: 02/23/2019</li>
          <li>Guitars, bass, keyboards: Gary King</li>
          <li>Words, vocal melodies and percussion instruments: Gretta Harley</li>
          <li>Drums: Kai Strandskov</li>
          <li>Bass on Blackbird, Little Worlds: Jane Mabry Smith</li>
          <li>Saxophones on Ling, Blackbird: Amy Denio</li>
        </ul>


        <ul class="list-unstyled">
          <li>Music arrangements: Gary King</li>
          <li>Produced and recorded: Gary King</li>
          <li>Recorded at House of Leisure, Terry Maloney’s Space, and Avast Recording Co., Seattle WA</li>
          <li>Mixed by Jack Endino and Gary King</li>
          <li>Mixed at Soundhouse Studio and House of Leisure, Seattle</li>
          <li>Mastered by Chris Hanzsek at HanszekAV, Seattle</li>
          <li>Website: Gary King<br/>
            Album cover: Gary King/Gretta Harley, Sunflower photo: Angela Casta&#241;eda<br/>
          </li>

          <li>&nbsp;</li>
          <li>Music by Gary King &copy;2019 Gary King Audio, ASCAP</li>
          <li>Words and vocal melodies: Gretta Harley &copy;2019 Pas Palapatas, ASCAP</li>
          <li>All Rights Reserved</li>
          <li>&#8471;Gary King Audio All rights reserved</li>
        </ul>

        <ul class="list-unstyled">
          <li>Terry Maloney, Doug "Sluggo" Owens, Brian Nelson, Don Farwell, Soundhouse Recording, Avast
            Recording Co., Rhonda Pelikan, Shaun Hardy
          </li>
        </ul>
      </div><!-- end col -->
    </div><!-- end container-->


    <div class="container">
      <div class="col-sm-12 col-md-4 col-lg-4">

        <h3>LIVE</h3>
        <ul class="list-unstyled">
          <li>Vocals: Gretta Harley</li>
          <li>Guitars: Gary King, Bob Watanabe</li>
          <li>Drums: Kai Strandskov</li>
          <li>Bass and backing vocals: Jane Mabry Smith</li>
          <li>Keyboards: Rob Knop</li>
        </ul>

        <ul class="list-unstyled">
          <li class="small-text">Website: Gary King</li>
        </ul>

      </div>

    </div><!-- end container -->
  </div>
</div>

